import * as React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { FeatureGridStyled, Tile } from '../components/postGrid';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const stringToColor = str => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var color = '#';
  for (var i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
};

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;
  const home = data.home;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Blog" />
        <Bio />
        <p>No blog posts found.</p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Blog" />
      <Bio />
      <h1 className="main-heading">Blog</h1>

      <div className="article-body">
        <FeatureGridStyled>
          <ol className="featured-block">
            {posts.map(post => {
              const image = getImage(post?.frontmatter?.featuredimage);
              const title = post.frontmatter.title || post.fields.slug;
              if (!image) return null;
              return (
                <Tile
                  key={post.fields.slug}
                  // backgroundColor={
                  //   !image ? stringToColor(post?.fields?.slug) : null
                  // }
                >
                  <Link
                    // className={`${!image ? 'blank-tile-wrapper' : ''}`}
                    to={post.fields.slug}
                    itemProp="url"
                  >
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <GatsbyImage image={image} alt={title} />
                      <div className="featured-footer">
                        <header>
                          <h4>
                            <span itemProp="headline">{title}</span>
                          </h4>
                        </header>
                        <section className="featured-description">
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                post.frontmatter.description || post.excerpt,
                            }}
                            itemProp="description"
                          />
                        </section>
                      </div>
                    </article>
                  </Link>
                </Tile>
              );
            })}
          </ol>
        </FeatureGridStyled>
      </div>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    home: markdownRemark(frontmatter: { templatekey: { eq: "index-page" } }) {
      id
      frontmatter {
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              width: 600
              aspectRatio: 1
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templatekey: { eq: "blog-post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredpost
          featuredimage {
            childImageSharp {
              gatsbyImageData(
                width: 600
                aspectRatio: 1
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
